body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
  /* font-family: "proxima-nova", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui.vertical.segment.not-last-child:last-child {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.ui.form .no-margin.fields {
  margin: 0;
}

/* animation: colorchange 5s infinite;
    animation-name: colorchange;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
} */

/* animation: colorchange 5s; animation-name followed by duration in seconds */
/* you could also use milliseconds (ms) or something like 2.5s */
/* -webkit-animation: colorchange 5s; Chrome and Safari */

.ui.form .colorchange-button .ui.button,
.ui.button.colorchange-button {
  /* animation-name: colorchange;
  animation-duration: 5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running; */
}

@keyframes colorchange {
  0% {
    background: #7d1868;
  }
  20% {
    background: #29b9bb;
  }
  40% {
    background: #8dcc49;
  }
  60% {
    background: #fba32a;
  }
  80% {
    background: #e14c91;
  }
  100% {
    background: #7d1868;
  }
}

@-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */ {
  0% {
    background: #7d1868;
  }
  20% {
    background: #29b9bb;
  }
  40% {
    background: #8dcc49;
  }
  60% {
    background: #fba32a;
  }
  80% {
    background: #e14c91;
  }
  100% {
    background: #7d1868;
  }
}

span.pink {
  color: #e14c91;
}

.strategies-list-container .ui.list {
  padding: 0 2em;
}
