.flicker-container {
  position: relative;
  width: 100%;
  height: 5em;
  text-align: center;
}
.shadow-1,
.shadow-2,
.shadow-3,
.shadow-4,
.shadow-5 {
  /* height: 0; */
  /* overflow: hidden; */
  /* margin: 0 !important; */
  /* padding: 0 !important; */
  /* visibility: hidden; */
  /* min-height: 0; */
  /* display: none; */
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  text-shadow: #fff 0px 0px 3px, #fff 0px 0px 6px, #fff 0px 0px 12px,
    #e14c91 0px 0px 10px, #e14c91 0px 0px 20px, #e14c91 0px 0px 40px,
    #e14c91 0px 0px 80px;
}

.shadow-1 {
  animation: flicker-1 15s linear 0s infinite;
  -webkit-animation: flicker-1 15s linear 0s infinite;
  -moz-animation: flicker-1 15s linear 0s infinite;
}

.shadow-2 {
  animation: flicker-1 15s linear 3s infinite;
  -webkit-animation: flicker-1 15s linear 3s infinite;
  -moz-animation: flicker-1 15s linear 3s infinite;
}
.shadow-3 {
  animation: flicker-1 15s linear 6s infinite;
  -webkit-animation: flicker-1 15s linear 6s infinite;
  -moz-animation: flicker-1 15s linear 6s infinite;
}
.shadow-4 {
  animation: flicker-1 15s linear 9s infinite;
  -webkit-animation: flicker-1 15s linear 9s infinite;
  -moz-animation: flicker-1 15s linear 9s infinite;
}

.shadow-5 {
  animation: flicker-1 15s linear 12s infinite;
  -webkit-animation: flicker-1 15s linear 12s infinite;
  -moz-animation: flicker-1 15s linear 12s infinite;
}

@keyframes flicker-1 {
  0%,
  20%,
  100% {
    opacity: 0;
    color: inherit;
  }
  0%,
  0.3%,
  0.9%,
  1.2%,
  2%,
  4%,
  19% {
    opacity: 1;
    filter: none;
  }
  0.2%,
  1.1%,
  3.0% {
    color: white;
    opacity: 0.12;
  }
}

.hero {
  background-image: url(https://storage.googleapis.com/mogulkit/Back-view-young-female-doing-barbell-squats-in-gym.-Woman-with-muscular-body-exercising.-Sports-and-fitness-concept..jpg) !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
}

.ui.inverted.segment.hero {
  margin: 0 !important;
  border-radius: 0 !important;
}
